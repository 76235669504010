<template>
  <Page>
    <Columns gap="small" class="my-6">
      <!-- Code Column -->
      <Column width="2/3" class="relative block overflow-hidden h-full text-[13px] leading-[18px] rounded-lg border border-gray-100/50 bg-white dark:bg-linear-to-r dark:from-slate-900 dark:to-slate-700 p-4 pb-7">
        <Box v-slot="{ elementWidth }" marginTop="xsmall">
          <Box full justifyContent="between" marginBottom="gutter" gapY="gutter" noWrap :shouldCollapse="elementWidth < 400">
            <Box gap="small" :shouldCollapse="elementWidth < 330">
              <!-- Button Prop Sidebar -->
              <Box>
                <Sidebar v-model="openButtonPropSidebar" heading="Text Props">
                  <Stack gap="xsmall">
                    <Text marginY="small" attention>
                      Below are all available props to the Text Component.
                    </Text>

                    <Stack gap="medium">
                      <!-- Checkboxes for Other Props -->
                      <Stack gap="small">
                        <Checkbox v-model="state.attention" label="Attention"/>
                        <Checkbox v-model="state.italic" label="Italic" />
                        <Stack>
                          <Checkbox v-model="state.center" label="Center" />
                          <Text size="xsmall" color="dark">(Only works with HTML elements, not Layout Components)</Text>
                        </Stack>
                      </Stack>

                      <!-- Dropdown for Color Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Text Colors</Label>
                        <Dropdown v-model="state.textColor">
                          <Option v-for="color in textColors" :key="color" :value="color">
                            {{ color }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Size Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Text Sizes</Label>
                        <Dropdown v-model="state.textSize">
                          <Option v-for="size in textSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Weight Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Text Weight</Label>
                        <Dropdown v-model="state.textWeight">
                          <Option v-for="weight in textWeights" :key="weight" :value="weight">
                            {{ weight }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Color Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Text Hover Color</Label>
                        <Dropdown v-model="state.hoverColor">
                          <Option v-for="color in textColors" :key="color" :value="color">
                            {{ color }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <Text marginTop="large" attention>
                        Below are all available Margin Props.
                      </Text>

                      <!-- Dropdown for Margin Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Margin</Label>
                        <Dropdown v-model="state.margin">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Margin X Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Margin X</Label>
                        <Dropdown v-model="state.marginX">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Margin Y Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Margin Y</Label>
                        <Dropdown v-model="state.marginY">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Margin Left Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Margin Left</Label>
                        <Dropdown v-model="state.marginLeft">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Margin Right Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Margin Right</Label>
                        <Dropdown v-model="state.marginRight">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Margin Top Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Margin Top</Label>
                        <Dropdown v-model="state.marginTop">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Margin Bottom Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Margin Bottom</Label>
                        <Dropdown v-model="state.marginBottom">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <Text marginTop="large" attention>
                        Below are all available Padding Props.
                      </Text>

                      <!-- Dropdown for Padding Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Padding</Label>
                        <Dropdown v-model="state.padding">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Padding X Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Padding X</Label>
                        <Dropdown v-model="state.paddingX">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Padding Y Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Padding Y</Label>
                        <Dropdown v-model="state.paddingY">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Padding Left Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Padding Left</Label>
                        <Dropdown v-model="state.paddingLeft">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Padding Right Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Padding Right</Label>
                        <Dropdown v-model="state.paddingRight">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Padding Top Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Padding Top</Label>
                        <Dropdown v-model="state.paddingTop">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Padding Bottom Prop -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Padding Bottom</Label>
                        <Dropdown v-model="state.paddingBottom">
                          <Option v-for="size in layoutSizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>
                    </Stack>
                  </Stack>
                  <!-- Open Sidebar -->
                </Sidebar>

                <Box @click="openButtonPropSidebar = true" justifyContent="center" class="text-sm text-slate-100 font-attention rounded-sm px-3 py-1 cursor-pointer bg-slate-500/75 border border-slate-400/50" :full="elementWidth < 330">
                  Text Props
                </Box>
              </Box>
              <!-- Reset Playground -->
              <p @click="resetPlayground" class="bg-red-700 px-3 py-1 text-sm text-center text-slate-100 font-attention rounded-sm cursor-pointer hover:bg-red-800">Reset</p>
            </Box>
            <!-- Import Statement Copy Button -->
            <Button
                @click="copy(importStatement)"
                color="gray"
                size="sm"
                outline
              >
              {{ copied ? 'Copied Import' : 'Copy Import' }}
            </Button>
          </Box>
        </Box>

        <!-- View HTML -->
          <div>
            <!-- Button Opening Tag -->
            <pre>&lt;<span class="text-lime-600 dark:text-lime-600 font-semibold">Text</span><span v-if="shouldShowNoPropClosingCarrot">&gt;</span></pre>
            <!-- Optional props -->
            <pre v-if="(state.textColor !== 'default' && state.textColor !== null)"><span class="text-orange-600 dark:text-orange-400 ml-2">color</span><span>="{{ state.textColor }}"</span></pre>
            <pre v-if="state.textSize !== 'base'"><span class="text-orange-600 dark:text-orange-400 ml-2">size</span><span>="{{ state.textSize }}"</span></pre>
            <pre v-if="state.textWeight !== 'normal'"><span class="text-orange-600 dark:text-orange-400 ml-2">weight</span><span>="{{ state.textWeight }}"</span></pre>
            <pre v-if="state.hoverColor"><span class="text-orange-600 dark:text-orange-400 ml-2">hover</span><span>="{{ state.hoverColor }}"</span></pre>
            <pre v-if="state.italic"><span class="text-orange-600 dark:text-orange-400 ml-2">italic</span></pre>
            <pre v-if="state.attention"><span class="text-orange-600 dark:text-orange-400 ml-2">attention</span></pre>
            <pre v-if="state.center"><span class="text-orange-600 dark:text-orange-400 ml-2">center</span></pre>
            <pre v-if="state.margin"><span class="text-orange-600 dark:text-orange-400 ml-2">margin</span><span>="{{ state.margin }}"</span></pre>
            <pre v-if="state.marginX"><span class="text-orange-600 dark:text-orange-400 ml-2">marginX</span><span>="{{ state.marginX }}"</span></pre>
            <pre v-if="state.marginY"><span class="text-orange-600 dark:text-orange-400 ml-2">marginY</span><span>="{{ state.marginY }}"</span></pre>
            <pre v-if="state.marginLeft"><span class="text-orange-600 dark:text-orange-400 ml-2">marginLeft</span><span>="{{ state.marginLeft }}"</span></pre>
            <pre v-if="state.marginRight"><span class="text-orange-600 dark:text-orange-400 ml-2">marginRight</span><span>="{{ state.marginRight }}"</span></pre>
            <pre v-if="state.marginTop"><span class="text-orange-600 dark:text-orange-400 ml-2">marginTop</span><span>="{{ state.marginTop }}"</span></pre>
            <pre v-if="state.marginBottom"><span class="text-orange-600 dark:text-orange-400 ml-2">marginBottom</span><span>="{{ state.marginBottom }}"</span></pre>
            <pre v-if="state.padding"><span class="text-orange-600 dark:text-orange-400 ml-2">padding</span><span>="{{ state.padding }}"</span></pre>
            <pre v-if="state.paddingX"><span class="text-orange-600 dark:text-orange-400 ml-2">paddingX</span><span>="{{ state.paddingX }}"</span></pre>
            <pre v-if="state.paddingY"><span class="text-orange-600 dark:text-orange-400 ml-2">paddingY</span><span>="{{ state.paddingY }}"</span></pre>
            <pre v-if="state.paddingLeft"><span class="text-orange-600 dark:text-orange-400 ml-2">paddingLeft</span><span>="{{ state.paddingLeft }}"</span></pre>
            <pre v-if="state.paddingRight"><span class="text-orange-600 dark:text-orange-400 ml-2">paddingRight</span><span>="{{ state.paddingRight }}"</span></pre>
            <pre v-if="state.paddingTop"><span class="text-orange-600 dark:text-orange-400 ml-2">paddingTop</span><span>="{{ state.paddingTop }}"</span></pre>
            <pre v-if="state.paddingBottom"><span class="text-orange-600 dark:text-orange-400 ml-2">paddingBottom</span><span>="{{ state.paddingBottom }}"</span></pre>
            <pre v-if="!shouldShowNoPropClosingCarrot">&gt;</pre>
            <pre><span> This is a sample text!</span></pre>
            <!-- Button Closing Tag -->
            <pre class="mt-1">&lt;/<span class="text-lime-600 dark:text-lime-600 font-semibold">Text</span>&gt;</pre>
          </div>

        <!-- Bottom gradient bar -->
        <span class="absolute inset-x-0 bottom-0 h-2 bg-linear-to-r from-green-300 via-blue-500 to-purple-600"/>
      </Column>

      <!-- Visual Block -->
      <Column class="h-72 p-4 bg-white dark:bg-charcoal-900 rounded-lg border border-gray-100/50">
        <Box justifyContent="center" alignItems="center" class="h-full">
          <Text
            :color="state.textColor"
            :size="state.textSize"
            :weight="state.textWeight"
            :hover="state.hoverColor"
            :italic="state.italic"
            :attention="state.attention"
            :center="state.center"
          >
            <div class="border-orange-400/85" :class="marginDimensions">
              <div class="border-lime-300/85" :class="paddingDimensions">
                <div>This is a sample text!</div>
              </div>
            </div>
          </Text>
        </Box>
      </Column>
    </Columns>
  </Page>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Box, Column, Columns, Page, Stack } from '@/components/Core/Layout'
import Sidebar from '@/components/Core/Sidebar.vue'
import { Dropdown, Option } from '@/components/Core/Form/Dropdown'
import Text from '@/components/Core/Text.vue'
import Checkbox from '@/components/Core/Form/Checkbox.vue'
import Label from '@/components/Core/Form/Label.vue'
import Button from '@/components/Core/Button.vue'
import { useClipboard } from '@vueuse/core'

const importStatement = ref('import Text from \'@/components/Core/Text.vue\'')
const { copy, copied } = useClipboard({ importStatement })

const openButtonPropSidebar = ref(false)

const state = ref({
  textColor: 'default',
  hoverColor: null,
  textSize: 'base',
  textWeight: 'normal',
  italic: false,
  attention: false,
  center: false,
  margin: null,
  marginX: null,
  marginY: null,
  marginLeft: null,
  marginRight: null,
  marginTop: null,
  marginBottom: null,
  padding: null,
  paddingX: null,
  paddingY: null,
  paddingLeft: null,
  paddingRight: null,
  paddingTop: null,
  paddingBottom: null
})

const initialState = ref(JSON.parse(JSON.stringify(state.value)))

const textColors = ref([
  'amber',
  'dark',
  'default',
  'green',
  'light',
  'purple',
  'red',
  'white'
])

const textSizes = ref([
  'xsmall',
  'small',
  'base',
  'large',
  'xlarge'
])

const textWeights = ref([
  'light',
  'normal',
  'medium',
  'semibold',
  'bold'
])

const layoutSizes = ref([
  'xxsmall',
  'xsmall',
  'small',
  'medium',
  'large',
  'xlarge',
  'xxlarge',
  'gutter'
])

const shouldShowNoPropClosingCarrot = computed(() => {
  return (state.value.textColor === 'default' || state.value.textColor === null) &&
    !state.value.hoverColor &&
    state.value.textSize === 'base' &&
    state.value.textWeight === 'normal' &&
    !state.value.italic &&
    !state.value.attention &&
    !state.value.center &&
    !state.value.margin &&
    !state.value.marginX &&
    !state.value.marginY &&
    !state.value.marginLeft &&
    !state.value.marginRight &&
    !state.value.marginTop &&
    !state.value.marginBottom &&
    !state.value.padding &&
    !state.value.paddingX &&
    !state.value.paddingY &&
    !state.value.paddingLeft &&
    !state.value.paddingRight &&
    !state.value.paddingTop &&
    !state.value.paddingBottom
})

const resetPlayground = () => {
  state.value = JSON.parse(JSON.stringify(initialState.value))
}

/**
 * DIMENSIONS
*/
const marginDimensions = computed(() => {
  let margin = {
    xxsmall: 'p-1',
    xsmall: 'p-2',
    small: 'p-3',
    medium: 'p-4',
    large: 'p-6',
    xlarge: 'p-8',
    xxlarge: 'p-10',
    gutter: 'p-5'
  }[state.value.margin]

  let marginX = {
    xxsmall: 'px-1',
    xsmall: 'px-2',
    small: 'px-3',
    medium: 'px-4',
    large: 'px-6',
    xlarge: 'px-8',
    xxlarge: 'px-10',
    gutter: 'px-5'
  }[state.value.marginX]

  let marginY = {
    xxsmall: 'py-1',
    xsmall: 'py-2',
    small: 'py-3',
    medium: 'py-4',
    large: 'py-6',
    xlarge: 'py-8',
    xxlarge: 'py-10',
    gutter: 'py-5'
  }[state.value.marginY]

  let marginLeft = {
    xxsmall: 'pl-1',
    xsmall: 'pl-2',
    small: 'pl-3',
    medium: 'pl-4',
    large: 'pl-6',
    xlarge: 'pl-8',
    xxlarge: 'pl-10',
    gutter: 'pl-5'
  }[state.value.marginLeft]

  let marginRight = {
    xxsmall: 'pr-1',
    xsmall: 'pr-2',
    small: 'pr-3',
    medium: 'pr-4',
    large: 'pr-6',
    xlarge: 'pr-8',
    xxlarge: 'pr-10',
    gutter: 'pr-5'
  }[state.value.marginRight]

  let marginTop = {
    xxsmall: 'pt-1',
    xsmall: 'pt-2',
    small: 'pt-3',
    medium: 'pt-4',
    large: 'pt-6',
    xlarge: 'pt-8',
    xxlarge: 'pt-10',
    gutter: 'pt-5'
  }[state.value.marginTop]

  let marginBottom = {
    xxsmall: 'pb-1',
    xsmall: 'pb-2',
    small: 'pb-3',
    medium: 'pb-4',
    large: 'pb-6',
    xlarge: 'pb-8',
    xxlarge: 'pb-10',
    gutter: 'pb-5'
  }[state.value.marginBottom]

  /**
   * Take all margins and remove the undefineds for those not selected
  */
  let marginsToArray =  [margin, marginX, marginY, marginLeft, marginRight, marginTop, marginBottom].filter(Boolean)

  /**
   * If array has length, create a string not separateed by ','.
  */
  if (marginsToArray.length) {
    return `border-dotted border-2 ${marginsToArray.toString().split(',').join(' ')}`
  }

  return null
})

const paddingDimensions = computed(() => {
  let padding = {
    xxsmall: 'p-1',
    xsmall: 'p-2',
    small: 'p-3',
    medium: 'p-4',
    large: 'p-6',
    xlarge: 'p-8',
    xxlarge: 'p-10',
    gutter: 'p-5'
  }[state.value.padding]

  let paddingX = {
    xxsmall: 'px-1',
    xsmall: 'px-2',
    small: 'px-3',
    medium: 'px-4',
    large: 'px-6',
    xlarge: 'px-8',
    xxlarge: 'px-10',
    gutter: 'px-5'
  }[state.value.paddingX]

  let paddingY = {
    xxsmall: 'py-1',
    xsmall: 'py-2',
    small: 'py-3',
    medium: 'py-4',
    large: 'py-6',
    xlarge: 'py-8',
    xxlarge: 'py-10',
    gutter: 'py-5'
  }[state.value.paddingY]

  let paddingLeft = {
    xxsmall: 'pl-1',
    xsmall: 'pl-2',
    small: 'pl-3',
    medium: 'pl-4',
    large: 'pl-6',
    xlarge: 'pl-8',
    xxlarge: 'pl-10',
    gutter: 'pl-5'
  }[state.value.paddingLeft]

  let paddingRight = {
    xxsmall: 'pr-1',
    xsmall: 'pr-2',
    small: 'pr-3',
    medium: 'pr-4',
    large: 'pr-6',
    xlarge: 'pr-8',
    xxlarge: 'pr-10',
    gutter: 'pr-5'
  }[state.value.paddingRight]

  let paddingTop = {
    xxsmall: 'pt-1',
    xsmall: 'pt-2',
    small: 'pt-3',
    medium: 'pt-4',
    large: 'pt-6',
    xlarge: 'pt-8',
    xxlarge: 'pt-10',
    gutter: 'pt-5'
  }[state.value.paddingTop]

  let paddingBottom = {
    xxsmall: 'pb-1',
    xsmall: 'pb-2',
    small: 'pb-3',
    medium: 'pb-4',
    large: 'pb-6',
    xlarge: 'pb-8',
    xxlarge: 'pb-10',
    gutter: 'pb-5'
  }[state.value.paddingBottom]

  /**
   * Take all paddings and remove the undefineds for those not selected
  */
  let paddingsToArray =  [padding, paddingX, paddingY, paddingLeft, paddingRight, paddingTop, paddingBottom].filter(Boolean)

  /**
   * If array has length, create a string not separateed by ','.
  */
  if (paddingsToArray.length) {
    return `border-dotted border-2 ${paddingsToArray.toString().split(',').join(' ')}`
  }

  return null
})
</script>