
<template>
  <div class="relative">
    <button
      @click="toggle"
      type="button"
      class="block focus:outline-hidden box-border"
      @focus="buttonHasFocus = true"
      @blur="buttonHasFocus = false"
    >
      <slot name="trigger" :hasFocus="buttonHasFocus" :isOpen="isOpen"></slot>
    </button>
    <div :class="[isOpen ? 'block' : 'hidden']">
      <button
        @click="isOpen = false"
        type="button"
        class="z-30 block fixed inset-0 w-full h-full cursor-default"
      ></button>
      <div :class="[dropdownClasses.all, dropdownClasses[direction], dropdownClasses[position]]">
        <slot name="dropdown" :toggle="toggle"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',

  props: {
    direction: {
      type: String,
      validator: val => ['top', 'bottom'].includes(val),
      default: 'bottom'
    },

    position: {
      type: String,
      validator: val => ['left', 'right'].includes(val),
      default: 'right'
    }
  },

  data() {
    return {
      buttonHasFocus: false,
      isOpen: false,
      dropdownClasses: {
        all: 'absolute z-40',
        top: 'bottom-full mb-2',
        bottom: 'top-full mt-2',
        left: 'left-0',
        right: 'right-0',
      }
    }
  },

  mounted() {
    this.onEscape = e => {
      if (!this.isOpen || e.key !== 'Escape') {
        return
      }
      this.isOpen = false
    }

    document.addEventListener('keydown', this.onEscape)
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this.onEscape)
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
