<template>
  <Box>
    <Checkbox
      :inputId="id"
      v-model="isChecked"
      :value="value"
      :binary="(typeof isChecked === 'boolean' || typeof value === 'boolean') ? true : null"
      :name="name"
      :disabled="disabled"
      :size="size"
      :pt="{
        root: {
          class: [
            // Alignment
            'self-center'
          ]
        },
        box: ({ props, context }) => ({
          class: [
            // Shape
            'border',

            // Colors
            {
              'border-charcoal-300 dark:border-charcoal-700': !context.checked && !props.invalid,
              'border-iris-500 bg-iris-600': context.checked
            },

            // Invalid State
            { 'border-red-500 dark:border-red-400': props.invalid },

            // States
            {'opacity-70 cursor-not-allowed pointer-events-auto': props.disabled},

            // Transitions
            'transition-colors',
            'duration-200'
          ]
        }),
        icon: {
          class: [
            'text-white'
          ]
        }
      }"
    />
    <Text :size="labelSize" :class="{'ml-2' : label}">{{ label }}</Text>
  </Box>
</template>

<script setup>
import { computed } from "vue";
import { Box } from '@/components/Core/Layout'
import Text from '@/components/Core/Text.vue'
import Checkbox from 'primevue/checkbox'

const props = defineProps({
  disabled: {
    type: Boolean
  },
  id: {
    type: String
  },
  label: {
    type: String
  },
  name: {
    type: String
  },
  value: {
    type: [String, Number, Boolean, Array],
    default: null
  },
  size: {
    type: String,
    validator: (val) => ['small', 'large'].includes(val),
    default: null
  }
})

const isChecked = defineModel()

const labelSize = computed(() => {
  let sizeObject = {
    small: 'small',
    null: 'base',
    large: 'large'
  }

  return sizeObject[props.size]
})
</script>