<template></template>

<script setup>
import { onMounted, inject, useSlots, onBeforeUnmount, Text } from 'vue'

const props = defineProps({
  value: [String, Object, Number, Boolean],
  trailingIcon: [String, Object],
  secondaryLabel: String,
  optionIcon: String,
  optionIconColor: String,
})

const slots = useSlots()

const api = inject('select')

onMounted(() => {
  let label = props.value

  const children = slots.default ? slots.default() : []

  if (children.length === 1 && children[0].type === Text) {
    label = children[0].children
  }

  if (api.register) {
    api.register({
      label,
      value: props.value,
      trailingIcon: props.trailingIcon,
      secondaryLabel: props.secondaryLabel,
      optionIcon: props.optionIcon,
      optionIconColor: props.optionIconColor
    })
  }
})

onBeforeUnmount(() => {
  if (api.unregister) {
    api.unregister(props.value)
  }
})
</script>

