<template>
  <Dialog v-model="config.isVisible" @closed="config.cancel">
    <div class="-mt-6 w-screen max-w-sm">
      <div class="mt-3 sm:mt-5">
        <div class="text-center">{{ config.title }}</div>
        <div class="mt-2">
          <div class="text-center">{{ config.message }}</div>
        </div>
      </div>
    </div>
    <div class="mt-4 flex justify-center items-center gap-4">
      <Button full color="purple" @click="config.confirm">{{ config.confirmLabel }}</Button>
      <Button full @click="config.cancel">{{ config.cancelLabel }}</Button>
    </div>
  </Dialog>
</template>

<script setup>
import { useConfirmDialog } from '@/composables/useConfirmDialog.js'
import Dialog from './Dialog.vue'
import Button from '@/components/Core/Button.vue'

const { config } = useConfirmDialog()
</script>

