export default {
  userUUID: null,
  username: null,
  appTheme: 'light',
  preferredOpportunityView: 'list',
  sfUserData: {},

  // Product Icons for Account Detail / Opportunities
  productIcons: [
    {
      title: 'AHV',
      img: new URL('./../../assets/icons/icn-ahv-color.png', import.meta.url).href,
      value: 'AHV',
      group: 'Other',
      version: 'Legacy'
    },
    {
      title: 'AOS',
      img: new URL('./../../assets/icons/icn-aos-color.png', import.meta.url).href,
      value: 'AOS',
      group: 'Cloud Infrastructure',
      version: 'Legacy'
    },
    {
      title: 'Beam',
      img: new URL('./../../assets/icons/icn-xi-beam-color.png', import.meta.url).href,
      value: 'Beam',
      group: 'Cloud Management',
      version: 'Legacy'
    },
    {
      title: 'Calm',
      img: new URL('./../../assets/icons/icn-calm-color.png', import.meta.url).href,
      value: 'Calm',
      group: 'Cloud Management',
      version: 'Legacy'
    },
    {
      title: 'NC2-AWS',
      // Use en-dash instead of dash by doing ( Option + - ) on Mac
      value: 'Clusters – AWS',
      group: 'Other',
      version: 'Legacy'
    },
    {
      title: 'NC2-Azure',
      // Use en-dash instead of dash by doing ( Option + - ) on Mac
      value: 'Clusters – Azure',
      group: 'Other',
      version: 'Legacy'
    },
    {
      title: 'Era',
      img: new URL('./../../assets/icons/icn-era-color.png', import.meta.url).href,
      value: 'Era',
      group: 'Database Services',
      version: 'Legacy'
    },
    {
      title: 'Files',
      img: new URL('./../../assets/icons/icn-files-color.png', import.meta.url).href,
      value: 'Files',
      group: 'Unified Storage',
      version: 'Legacy'
    },
    {
      title: 'Flow',
      img: new URL('./../../assets/icons/icn-flow-color.png', import.meta.url).href,
      value: 'Flow',
      group: 'Cloud Infrastructure',
      version: 'Legacy'
    },
    {
      title: 'Frame',
      img: new URL('./../../assets/icons/icn-xi-frame-color.png', import.meta.url).href,
      value: 'Frame',
      group: 'EUC',
      version: 'Legacy'
    },
    {
      title: 'Karbon',
      img: new URL('./../../assets/icons/icn-karbon-color.png', import.meta.url).href,
      value: 'Karbon',
      group: 'Cloud Infrastructure',
      version: 'Legacy'
    },
    {
      title: 'Mine',
      img: new URL('./../../assets/icons/icn-mine-color.png', import.meta.url).href,
      value: 'Mine',
      group: 'Unified Storage',
      version: 'Legacy'
    },
    {
      title: 'Objects',
      img: new URL('./../../assets/icons/icn-objects-color.png', import.meta.url).href,
      value: 'Objects',
      group: 'Unified Storage',
      version: 'Legacy'
    },
    {
      title: 'Prism',
      img: new URL('./../../assets/icons/icn-prism-color.png', import.meta.url).href,
      value: 'Prism',
      group: 'Cloud Management',
      version: 'Legacy'
    },
    {
      title: 'Xi Leap',
      img: new URL('./../../assets/icons/icn-xi-leap-color.png', import.meta.url).href,
      value: 'Xi Leap',
      group: 'Cloud Infrastructure',
      version: 'Legacy'
    },
    // PNP 2.0
    {
      title: 'NC2-AWS',
      value: 'NC2-AWS',
      version: '2.0'
    },
    {
      title: 'NCI',
      img: new URL('./../../assets/icons/icn-aos-color.png', import.meta.url).href,
      value: 'NCI',
      group: 'Cloud Infrastructure',
      version: '2.0'
    },
    {
      title: 'NCI-Data',
      img: new URL('./../../assets/icons/icn-aos-color.png', import.meta.url).href,
      value: 'NCI Data',
      group: 'Cloud Infrastructure',
      version: '2.0'
    },
    {
      title: 'NCM',
      img: new URL('./../../assets/icons/icn-ncm-color.png', import.meta.url).href,
      value: 'NCM',
      group: 'Cloud Management',
      version: '2.0'
    },
    {
      title: 'NDB',
      img: new URL('./../../assets/icons/icn-era-color.png', import.meta.url).href,
      value: 'NUTANIX DATABASE SERVICE',
      group: 'Database Services',
      version: '2.0'
    },
    {
      title: 'NUS',
      img: new URL('./../../assets/icons/icn-nus-color.png', import.meta.url).href,
      value: 'UNIFIED STORAGE',
      group: 'Unified Storage',
      version: '2.0'
    },
    {
      title: 'EUC',
      img: new URL('./../../assets/icons/icn-aos-color.png', import.meta.url).href,
      value: 'EUC',
      group: 'EUC',
      version: '2.0'
    },

  ],

  deploymentStatuses: [
    {
      name :'Using At Scale',
      value: 'Using At Scale',
      definition: 'Using Nutanix for this workload; their needed scale and capacity are currently met. No immediate incremental opportunity to capture.',
      shortDescription: 'Needed scale and capacity are currently met.',
      implications: 'No immediate expansion opportunity.'
    },
    {
      name: 'Using, Opportunity To Expand',
      value: 'Using, Opportunity To Expand',
      definition: 'Using Nutanix for part of this workload; team has identified a potential expansion opportunity within this same workload. Customer may or may not have expressed interest in expansion.',
      shortDescription: 'Potential expansion opportunity for same workload.',
      implications: 'Expansion opportunity.'
    },
    {
      name: 'Using, Not Buying Anymore',
      value: 'Using, Not Buying Anymore',
      definition: 'Using Nutanix for part of this workload; team has identified potential expansion but customer has expressed no interest in Nutanix.',
      shortDescription: 'Workload not fully captured but customer has stated no interest in expansion.',
      implications: 'No immediate expansion opportunity.'
    },
    {
      name: 'Nutanix Sold, Not Yet Deployed',
      value: 'Nutanix Sold, Not Yet Deployed',
      definition: 'Not yet running this workload on Nutanix but have sold a solution for this workload.',
      shortDescription: 'Not yet running this workload on Nutanix but have sold a solution for this workload.',
      implications: 'No immediate expansion opportunity.'
    },
    {
      name: 'Don’t Own - Active Campaign',
      value: 'Don’t Own - Active Campaign',
      definition: 'Not running this workload on Nutanix, and account team is actively engaged – there may be an open opportunity, invited to a bootcamp or webinar, etc.',
      shortDescription: 'Workload not running on Nutanix but account team actively engaged with open opportunity, bootcamp, etc.',
      implications: 'Expansion opportunity.'
    },
    {
      name: 'Don’t Own - No Active Campaign',
      value: 'Don’t Own - No Active Campaign',
      definition: 'Not running this workload on Nutanix, but account team has not engaged to understand opportunity. This could be a competitor take-out.',
      shortDescription: 'Workload not running on Nutanix but no active engagement from customer.',
      implications: 'Expansion opportunity.'
    },
    {
      name: 'Competitor',
      value: 'Competitor',
      definition: 'Not running this workload on Nutanix; account team has engaged customer for unsuccessful competitor takeout.  Will continue to run on competitor solution. Use this when you have confirmed customer is “Not interested” and you have competitor information to capture.',
      shortDescription: 'Customer has stated they will remain with a known competitor for this workload.',
      implications: 'No immediate expansion opportunity. Document the competitor and use case.'
    },
    {
      name: 'Not Interested / Not Relevant',
      value: 'Not Interested / Not Relevant',
      definition: 'Not running this workload on Nutanix; Account team has engaged customer and customer is not interested in leveraging Nutanix at this time. Capture notes.',
      shortDescription: 'Customer has stated they are not interested in using Nutanix for this workload.',
      implications: 'No immediate expansion opportunity.'
    }
  ],

  workloadAccountId: '',

  approvedImplementationPartners: [
    {
      name: 'SCALEUP CONSULTORIA E REPRESENTACAO COMERCIAL LTDA',
      id: '0010e00001JfhGFAAZ',
      product: 'Calm / NCM',
      theater: 'Americas'
    },
    {
      name: 'It Outsource',
      id: '0010e00001OE53gAAD',
      product: 'Calm / NCM',
      theater: 'Americas'
    },
    {
      name: 'EnableIT',
      id: '0010e00001Kxp7bAAB',
      product: 'Calm / NCM',
      theater: 'Americas'
    },
    {
      name: 'Iono, Inc',
      id: '0016000000xidBOAAY',
      product: 'Calm / NCM',
      theater: 'Americas'
    },
    {
      name: 'Sirius Computer Solutions - US',
      id: '0016000000n7Bc1AAE',
      product: 'Calm / NCM',
      theater: 'Americas'
    },
    {
      name: 'CitiusCloud Services LLP',
      id: '0010e00001ODEouAAH',
      product: 'Calm / NCM',
      theater: 'APAC'
    },
    {
      name: 'Iono, Inc',
      id: '0016000000xidBOAAY',
      product: 'Calm / NCM',
      theater: 'APAC'
    },
    {
      name: 'PT Helios Informatika Nusantara',
      id: '0010e00001PHfISAA1',
      product: 'Calm / NCM',
      theater: 'APAC'
    },
    {
      name: 'Digital Network Solution Pvt. Ltd.',
      id: '0010e00001L0gHuAAJ',
      product: 'Calm / NCM',
      theater: 'APAC'
    },
    {
      name: 'SCSI Co., Ltd.',
      id: '0013200001Inl0yAAB',
      product: 'Calm / NCM',
      theater: 'APAC'
    },
    {
      name: 'Teleconsys',
      id: '0013200001IoBl6AAF',
      product: 'Calm / NCM',
      theater: 'EMEA'
    },
    {
      name: 'Move AS',
      id: '00160000014CXJfAAO',
      product: 'Calm / NCM',
      theater: 'EMEA'
    },
    {
      name: 'Datacentrix',
      id: '001600000154I3XAAU',
      product: 'Calm / NCM',
      theater: 'EMEA'
    },

    {
      name: 'SCALEUP CONSULTORIA E REPRESENTACAO COMERCIAL LTDA',
      id: '0010e00001JfhGFAAZ',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'Americas'
    },
    {
      name: 'It Outsource',
      id: '0010e00001OE53gAAD',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'Americas'
    },
    {
      name: 'EnableIT',
      id: '0010e00001Kxp7bAAB',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'Americas'
    },
    {
      name: 'KRISH COMPUSOFT SERVICES',
      id: '0010e00001Q736GAAR',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'Americas'
    },
    {
      name: 'Logic Found',
      id: '0010e00001PFa5MAAT',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'Americas'
    },
    {
      name: 'Princeton It Services',
      id: '0010e00001Q7CuUAAV',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'Americas'
    },
    {
      name: 'Sirius Computer Solutions - US',
      id: '0016000000n7Bc1AAE',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'Americas'
    },
    {
      name: 'Trace3',
      id: '0016000000pFkDfAAK',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'Americas'
    },
    {
      name: 'MOQdigital',
      id: '00160000013T5lpAAC',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'APAC'
    },
    {
      name: 'Wardy It Solutions',
      id: '0010e00001QxnH8AAJ',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'APAC'
    },
    {
      name: 'CitiusCloud Services LLP',
      id: '0010e00001ODEouAAH',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'APAC'
    },
    {
      name: 'Iono, Inc',
      id: '0016000000xidBOAAY',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'APAC'
    },
    {
      name: 'PT Helios Informatika Nusantara',
      id: '0010e00001PHfISAA1',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'APAC'
    },
    {
      name: 'Neoclova',
      id: '0010e00001Qx8ahAAB',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'APAC'
    },
    {
      name: 'SCSI Co., Ltd.',
      id: '0013200001Inl0yAAB',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'APAC'
    },
    {
      name: 'Move AS',
      id: '00160000014CXJfAAO',
      product: 'Era / NUTANIX DATABASE SERVICE',
      theater: 'EMEA'
    }
  ],


  vendorProductMapping : {
    'AWS': [
      {
        label: 'AWS',
        value: 'AWS'
      },
      {
        label: 'AWS Outposts',
        value: 'AWS Outposts'
      },
    ],
    'Cisco': [
      {
        label: 'ACI',
        value: 'ACI'
      },
      {
        label: 'CloudCenter (CliqR)',
        value: 'CloudCenter (CliqR)'
      },
      {
        label: 'FlashStack (w Pure)',
        value: 'FlashStack (w Pure)'
      },
      {
        label: 'HyperFlex',
        value: 'HyperFlex'
      },
      {
        label: 'Intersite',
        value: 'Intersite'
      }
    ],
    'Citrix': [
      {
        label: 'XenApp',
        value: 'XenApp'
      },
      {
        label: 'XenDesktop',
        value: 'XenDesktop'
      }
    ],
    'Dell/EMC': [
      {
        label: 'Avamar/Data Domain',
        value: 'Avamar/Data Domain'
      },
      {
        label: 'Compellent',
        value: 'Compellent'
      },
      {
        label: 'Isilon',
        value: 'Isilon'
      },
      {
        label: 'Unity',
        value: 'Unity'
      },
      {
        label: 'VMAX',
        value: 'VMAX'
      },
      {
        label: 'VNX (depricated)',
        value: 'VNX (depricated)'
      },
      {
        label: 'VxBlock',
        value: 'VxBlock'
      },
      {
        label: 'VxFLEX (ScaleIO)',
        value: 'VxFLEX (ScaleIO)'
      },
      {
        label: 'VxRACK SDDC (depricated)',
        value: 'VxRACK SDDC (depricated)'
      },
      {
        label: 'VxRAIL',
        value: 'VxRAIL'
      },
      {
        label: 'XtremeIO',
        value: 'XtremeIO'
      },
    ],
    'Google': [
      {
        label: 'Google Anthos',
        value: 'Google Anthos'
      },
      {
        label: 'Google Cloud Platform',
        value: 'Google Cloud Platform'
      }
    ],
    'HPE': [
      {
        label: '3PAR',
        value: '3PAR'
      },
      {
        label: 'Nimble',
        value: 'Nimble'
      },
      {
        label: 'Siimplivity',
        value: 'Siimplivity'
      },
      {
        label: 'Synergy+vSAN',
        value: 'Synergy+vSAN'
      }
    ],
    'Microsoft': [
      {
        label: 'Microsoft Azure',
        value: 'Microsoft Azure'
      },
      {
        label: 'Microsoft AzureStack',
        value: 'Microsoft AzureStack'
      }
    ],
    'NetApp': [
      {
        label: 'AF FAS A-Series',
        value: 'AF FAS A-Series'
      },
      {
        label: 'Flexpod',
        value: 'Flexpod'
      },
      {
        label: 'Hybrid FAS',
        value: 'Hybrid FAS'
      },
      {
        label: 'Legacy 7-mode FAS',
        value: 'Legacy 7-mode FAS'
      },
      {
        label: 'NetApp HCI',
        value: 'NetApp HCI'
      },
      {
        label: 'StorageGRID(object)',
        value: 'StorageGRID(object)'
      },
    ],
    'Oracle': [
      {
        label: 'Oracle Cloud',
        value: 'Oracle Cloud'
      },
    ],
    'Other (please specify in comments)': [
      {
        label: 'Other',
        value: 'Other'
      },
    ],
    'VMware': [
      {
        label: 'Horizon',
        value: 'Horizon'
      },
      {
        label: 'NSX',
        value: 'NSX'
      },
      {
        label: 'NSX-T',
        value: 'NSX-T'
      },
      {
        label: 'VMC on AWS',
        value: 'VMC on AWS'
      },
      {
        label: 'vRealize Automation',
        value: 'vRealize Automation'
      },
      {
        label: 'vRealize Operations',
        value: 'vRealize Operations'
      },
      {
        label: 'vSAN',
        value: 'vSAN'
      },
      {
        label: 'vSphere',
        value: 'vSphere'
      },
    ]
  }
}
