<template>
  <CascadeSelect
    v-model="isOpen"
    @update:modelValue="(value) => $emit('update:modelValue', value)"
    :options="options"
    :optionLabel="optionLabel"
    :optionValue="optionValue"
    :optionGroupLabel="optionGroupLabel"
    :optionGroupChildren="optionGroupChildren"
    placeholder="Select an option"
    :pt="{
      root: ({ props, state }) => ({
        class: [
         // Shape
         'border',

         // Color and Background
          {'border-charcoal-300 dark:border-charcoal-700' : !props.invalid},
          {'opacity-70 cursor-not-allowed pointer-events-auto': props.disabled},

          // Font
          'text-sm',
          'text-charcoal-800 dark:text-charcoal-200'
        ]
      }),

      overlay: {
        class: [
          'bg-white dark:bg-charcoal-900'
        ]
      },

      listContainer: {
        class: [
          // Shape
          'border',
          'rounded-md',

          // Color and Background
          'border-charcoal-300 dark:border-charcoal-700',

        ]
      },

      option: ({ context }) => ({
        class: [
          'group',

          // Font
          'text-sm',

          // Color and Background
          'hover:bg-iris-600 hover:text-white',
          { 'bg-iris-500 text-white': context.active },
        ]
      }),

      optionList: {
        class: [
          // Shape
          'border',

          // Color and Background
          'border-charcoal-300 dark:border-charcoal-700',
          'bg-white dark:bg-charcoal-900'
        ]
      },

      groupIcon: ({ context }) => ({
        class: [
          // Color and Background
          { 'text-white': context.active },
          'text-charcoal-800 dark:text-charcoal-200 group-hover:text-white'
        ]
      })
    }"
  />
</template>

<script setup>
import CascadeSelect from 'primevue/cascadeselect'

defineProps({
  optionGroupChildren: {
    type: Array
  },
  optionGroupLabel: {
    type: String
  },
  optionLabel: {
    type: String
  },
  optionValue: {
    type: String
  },
  options: {
    type: Array
  },
  placeholder: {
    type: String
  }
})

const isOpen = defineModel()
</script>