<template>
  <Select
    v-model="selection"
    :options="options"
    optionValue="value"
    placeholder="Select one"
    class="w-full"
    :disabled="disabled"
    :invalid="error"
    :showClear="showClear"
    checkmark
    :highlightOnSelect="false"
    :pt="{
      root: ({ props, state }) => ({
        class: [
         // Shape
         'border',

         // Color and Background
          {'border-charcoal-300 dark:border-charcoal-700' : !props.invalid},
          {'opacity-70 cursor-not-allowed pointer-events-auto': props.disabled},

          // Font
          'text-sm',

          // Invalid State
          {'border-red-500': props.invalid},
        ]
      }),
      overlay: {
        class: [
          // Color and Background
          'bg-white dark:bg-charcoal-900',
          'border border-charcoal-300 dark:border-charcoal-700'
        ]
      },
      option: ({ context }) => ({
        class: [
          'group',

          // Font
          'text-sm',

          // Color and Background
          'hover:bg-iris-600 hover:text-white',
          { 'bg-transparent text-inherit': context.focused },

          // Misc
          'truncate',
        ]
      }),
      optionCheckIcon: {
        class: [
          // Color
          'text-iris-500 dark:text-iris-300 group-hover:text-white'
        ]
      }
    }"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value" class="flex items-center ">
        <div>{{ slotProps.value }}</div>
      </div>
      <span v-else class="flex-1">
        {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div class="flex items-center order-1">
        <div>{{ slotProps.option.label }}</div>
      </div>
    </template>

  </Select>

  <slot/>
</template>

<script setup>
import { ref, provide } from 'vue'
import Select from 'primevue/select'

defineProps({
  disabled: {
    type: Boolean
  },
  error: {
    type: Boolean
  },
  showClear: {
    type: Boolean
  }
})

const selection = defineModel({ required: true })

const options = ref([])

/**
 * Provide <Option/> components when an API to register and unregister
 * themselves as options when they are mounted/unmounted.
*/
provide('dropdown', {
  register(option) {
    options.value.push(option)
  },

  unregister(value) {
    options.value = options.value.filter(option => option.value !== value)
  }
})
</script>