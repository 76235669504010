<template>
  <Drawer
    v-model:visible="isOpen"
    @after-hide="emit('close')"
    :position="position"
    :showCloseIcon="showCloseIcon"
    blockScroll
    :class="[(position === 'left' || position === 'right'), dynamicWidth ]"
    :pt="{
      root: {
        class: [
        // Shape
          'border-0 dark:border',
          'shadow-lg',

          // Colors
          'dark:border-charcoal-800',
          'relative'
        ]
      },
      pcCloseButton: {
        root: {
          class: [
            'self-start'
          ]
        }
      }
    }"
  >
    <!--
      Handling of Header of the Sidebar. Either via the prop header="" or using a slot
      inside of parent instantiating this.
    -->
    <template #header>
      <div v-if="heading" class="font-semibold text-2xl">
        {{ heading }}
      </div>
      <slot v-else name="header" />
    </template>

    <!-- Slot for all data content for the Sidebar -->
    <slot />
  </Drawer>
</template>

<script setup>
import Drawer from 'primevue/drawer'

defineProps({
  heading: {
    type: String
  },
  position: {
    type: String,
    default: 'left'
  },
  showCloseIcon: {
    type: Boolean,
    default: true
  },
  dynamicWidth: {
    type: String,
    default: 'w-full! sm:w-[30rem]!'
  }
})

const emit = defineEmits(['close'])

const isOpen = defineModel()
</script>