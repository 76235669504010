<template>
  <div :class="[
    'flex flex-col flex-1',
    // Full padding
    {
      'p-1': padding === 'xxsmall',
      'p-2': padding === 'xsmall',
      'p-3': padding === 'small',
      'p-4': padding === 'medium',
      'p-6': padding === 'large',
      'p-8': padding === 'xlarge',
      'p-10': padding === 'xxlarge',
      'p-5': padding === 'gutter',
    }
  ]">
    <slot />
  </div>
</template>

<script setup>
defineProps({
  padding: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  }
})
</script>