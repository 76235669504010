<template>
  <div class="text-sm flex shrink-0 leading-5 font-medium text-charcoal-800 dark:text-charcoal-200 gap-x-1">
    <slot />
    <Icon v-show="required" icon="streamline:asterisk-1-solid" class="text-red-500 size-2 -mt-0.5" />
  </div>
</template>

<script setup>
import { Icon } from '@iconify/vue'

defineProps({
  required: {
    type: Boolean
  }
})
</script>
