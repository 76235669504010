<template>
  <div class="flex flex-col flex-1 gap-x-2">
    <Columns gap="small" class="my-6">
      <!-- Code Column -->
      <Column width="2/3" class="relative block overflow-hidden h-full text-[13px] leading-[18px] rounded-lg border border-gray-100/50 bg-white dark:bg-linear-to-r dark:from-slate-900 dark:to-slate-700 p-4 pb-7">
        <Box v-slot="{ elementWidth }" marginTop="xsmall">
          <Box full justifyContent="between" marginBottom="gutter" gapY="gutter" noWrap :shouldCollapse="elementWidth < 400">
            <Box gap="small" :shouldCollapse="elementWidth < 330">
              <!-- Listbox Prop Sidebar -->
              <Box>
                <Sidebar v-model="openListboxPropSidebar" heading="Listbox Props">
                  <Stack gap="xsmall">
                    <Text marginY="small" attention>
                      Below are all available props to the Listbox Component.
                    </Text>
                    <Text marginY="xsmall">
                      The following props are
                      <span class="italic font-attention">required</span>:
                    </Text>
                    <Checkbox v-model="state.id" label="Id" disabled />
                    <Checkbox v-model="state.modelValue" label="v-model" disabled />
                    <Text marginY="xsmall">
                      The following props are
                      <span class="italic font-attention">optional</span>:
                    </Text>
                    <Checkbox v-model="state.autocomplete" label="Autocomplete" />
                    <Checkbox v-model="state.autocompleteWithFunction" label="Autocomplete (with API function)" />
                    <Checkbox v-model="state.changed" label="Changed" />
                    <Checkbox v-model="state.contrast" label="Contrast" />
                    <Checkbox v-model="state.debounce" label="Debounce [for Autocomplete]" />
                    <Checkbox v-model="state.disabled" label="Disabled" />
                    <Checkbox v-model="state.error" label="Error" />
                    <Checkbox v-model="state.icon" label="Icon" />
                    <Checkbox v-model="state.iconColor" label="Icon Color" />
                    <Checkbox v-model="state.loading" label="Loading" />
                    <Checkbox v-model="state.minimal" label="Minimal" />
                    <Checkbox v-model="state.multiple" label="Multiple" />
                    <Checkbox v-model="state.nullable" label="Nullable" />
                    <Checkbox v-model="state.placeholder" label="Placeholder" />
                  </Stack>
                </Sidebar>
                <Box @click="openListboxPropSidebar = true" justifyContent="center" class="text-sm text-slate-100 font-attention rounded-sm px-3 py-1 cursor-pointer bg-slate-500/75 border border-slate-400/50" :full="elementWidth < 330">
                  Listbox Props
                </Box>
              </Box>

              <!-- Options Prop Sidebar -->
              <Box>
                <Sidebar v-model="openOptionPropSidebar" heading="Option Props">
                  <Stack gap="xsmall">
                    <Text marginY="small" attention>
                      Below are all available props to the Option.
                    </Text>
                    <Text marginY="xsmall">
                      The following props are
                      <span class="italic font-attention">required</span>:
                    </Text>
                    <Checkbox v-model="state.optionKey" label="key" disabled />
                    <Checkbox v-model="state.optionValue" label="value" disabled />
                    <Text marginY="xsmall">
                      The following props are
                      <span class="italic font-attention">optional</span>:
                    </Text>
                    <Checkbox v-model="state.secondaryLabel" label="secondaryLabel" />
                    <Checkbox v-model="state.trailingIcon" label="trailingIcon" />
                  </Stack>
                </Sidebar>
                <Box @click="openOptionPropSidebar = true" justifyContent="center" class="text-sm text-slate-100 font-attention rounded-sm px-3 py-1 cursor-pointer bg-slate-500/75 border border-slate-400/50" :full="elementWidth < 330">
                  Option Props
                </Box>
              </Box>
              <p @click="resetPlayground" class="bg-red-700 px-3 py-1 text-sm text-center text-slate-100 font-attention rounded-sm cursor-pointer hover:bg-red-800">Reset</p>
            </Box>

            <!-- Code/Data Buttons -->
            <Box gapX="xsmall" justifyContent="end">
              <Icon
                @click="changeCodeView('view')"
                icon="heroicons:code-bracket"
                class="w-7 h-7 cursor-pointer rounded-sm shrink-0 p-1 text-slate-500 hover:bg-slate-500/10 dark:text-slate-100 dark:hover:bg-slate-100/5"
                v-tippy
                content="View Code"
              />
              <Icon
                @click="changeCodeView('data')"
                icon="heroicons:cog-6-tooth"
                class="w-7 h-7 cursor-pointer rounded-sm shrink-0 p-1 text-slate-500 hover:bg-slate-500/10 dark:text-slate-100 dark:hover:bg-slate-100/5"
                v-tippy
                content="View Data"
              />
            <!-- Import Statement Copy Button -->
              <Button
                @click="copy(importStatement)"
                color="gray"
                size="sm"
                outline
              >
                {{ copied ? 'Copied Import' : 'Copy Import' }}
              </Button>
            </Box>
          </Box>
        </Box>

        <!-- View HTML -->
        <template v-if="state.viewHTML">
          <div id="code">
            <!-- Label -->
            <pre v-if="state.label" class="mb-1">&lt;<span class="text-lime-600 dark:text-lime-600 font-semibold">Label</span>&gt;Listbox Example&lt;<span class="text-lime-600 dark:text-lime-600 font-semibold">/Label</span>&gt;</pre>
            <!-- Listbox Opening Tag -->
            <pre>&lt;<span class="text-lime-600 dark:text-lime-600 font-semibold">Listbox</span></pre>
            <!-- Required props (id + v-model) -->
            <pre><span class="text-orange-600 dark:text-orange-400 ml-2">id</span><span>="listboxExample"</span></pre>
            <pre><span class="text-orange-600 dark:text-orange-400 ml-2">v-model</span><span>="exampleValues"</span></pre>

            <!-- Optional props -->
            <pre v-if="state.autocomplete" class="text-orange-600 dark:text-orange-400 ml-2">autocomplete</pre>
            <pre v-if="state.autocompleteWithFunction"><span class="text-orange-600 dark:text-orange-400 ml-2">autocomplete</span><span>="searchUsers"</span></pre>
            <pre v-if="state.changed" class="text-orange-600 dark:text-orange-400 ml-2">changed</pre>
            <pre v-if="state.contrast" class="text-orange-600 dark:text-orange-400 ml-2">contrast</pre>
            <pre v-if="state.debounce"><span class="text-orange-600 dark:text-orange-400 ml-2">:debounce</span><span>="500"</span></pre>
            <pre v-if="state.disabled" class="text-orange-600 dark:text-orange-400 ml-2">disabled</pre>
            <pre v-if="state.error" class="text-orange-600 dark:text-orange-400 ml-2">error</pre>
            <pre v-if="state.icon"><span class="text-orange-600 dark:text-orange-400 ml-2">icon</span><span>="heroicons:bug-ant"</span></pre>
            <pre v-if="state.iconColor"><span class="text-orange-600 dark:text-orange-400 ml-2">iconColor</span><span>="text-yellow-500"</span></pre>
            <pre v-if="state.loading"><span class="text-orange-600 dark:text-orange-400 ml-2">:loading</span><span>="loading"</span>
            </pre>
            <pre v-if="state.minimal" class="text-orange-600 dark:text-orange-400 ml-2">minimal</pre>
            <pre v-if="state.multiple" class="text-orange-600 dark:text-orange-400 ml-2">multiple</pre>
            <pre v-if="state.nullable" class="text-orange-600 dark:text-orange-400 ml-2">nullable</pre>
            <pre v-if="state.placeholder"><span class="text-orange-400"> placeholder</span><span>="Yay! A new placeholder"</span></pre>
            <pre>&gt;</pre>

            <!-- Options Instantiations -->
            <pre class="ml-2">&lt;<span class="text-lime-600 dark:text-lime-600 font-semibold">Option</span></pre>
            <pre><span class="text-lime-600 dark:text-lime-600 ml-4">v-for</span><span>="<span class="text-blue-500 dark:text-blue-300">option</span> in <span class="text-blue-500 dark:text-blue-300">exampleOptions</span>"</span></pre>
            <pre><span class="text-orange-600 dark:text-orange-400 ml-4">:key</span>="<span class="text-blue-500 dark:text-blue-300">option.value</span>"</pre>
            <pre><span class="text-orange-600 dark:text-orange-400 ml-4">:value</span>="<span class="text-blue-500 dark:text-blue-300">option.value</span>"</pre>
            <pre v-if="state.secondaryLabel"><span class="text-orange-600 dark:text-orange-400 ml-4">:secondaryLabel</span>="<span class="text-blue-500 dark:text-blue-300">option.email</span>"</pre>
            <pre v-if="state.trailingIcon"><span class="text-orange-600 dark:text-orange-400 ml-4">:trailingIcon</span>="<span class="text-blue-500 dark:text-blue-300">option.trailingIcon</span>"</pre>
            <pre class="ml-2">&gt;</pre>
            <pre><span class="text-blue-500 dark:text-blue-300 ml-4"><span class="text-yellow-500">&lcub;&lcub;</span> option.value <span class="text-yellow-500">&rcub;&rcub;</span></span></pre>
            <pre class="ml-2">&lt;/<span class="text-lime-600 dark:text-lime-600 font-semibold">Option</span>&gt;</pre>

            <!-- Listbox Closing Tag -->
            <pre class="mt-1">&lt;/<span class="text-lime-600 dark:text-lime-600 font-semibold">Listbox</span>&gt;</pre>
          </div>
        </template>

        <!-- View Data -->
        <Stack v-show="state.viewData" gap="medium">
          <Heading :level="3">
            Example of options array object structure:
          </Heading>
          <pre id="arrayOfOptionsAsHTML" class="text-charcoal-800 dark:text-charcoal-200 overflow-auto"></pre>
        </Stack>

        <!-- Bottom gradient bar -->
        <span class="absolute inset-x-0 bottom-0 h-2 bg-linear-to-r from-green-300 via-blue-500 to-purple-600"/>
      </Column>

      <!-- Visual Block -->
      <Column class="h-72 p-4 bg-white dark:bg-charcoal-900 rounded-lg border border-gray-100/50">
        <span v-if="state.autocompleteWithFunction" class="text-xs italic mb-0.5">Use the name [ John ] as search term</span>
        <Listbox
          id="listboxExample"
          v-model="state.exampleValue"
          :autocomplete="state.autocompleteWithFunction ? mimicAPICall : state.autocomplete"
          :changed="mimicOnChange"
          :contrast="state.contrast"
          :debounce="state.debounceNumber"
          :disabled="state.disabled"
          :error="mimicOnError"
          :icon="state.icon ? 'heroicons:bug-ant' : null"
          :iconColor="state.iconColor ? 'text-yellow-500' : null"
          :loading="state.loading"
          :minimal="state.minimal"
          :multiple="state.multiple"
          :nullable="state.nullable"
          :placeholder="placeholder"
          :key="updateKey"
        >
          <Option
            v-for="option in exampleOptions"
            :key="option.value"
            :value="option.value"
            :secondaryLabel="state.secondaryLabel ? option.email : null"
            :trailingIcon="state.trailingIcon ? option.trailingIcon : null"
          >
            {{ option.value }}
          </Option>
        </Listbox>
      </Column>
    </Columns>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { Listbox, Option } from '@/components/Core/Form/Listbox'
import { Box, Columns, Column, Stack } from '@/components/Core/Layout'
import { Icon } from '@iconify/vue'
import Text from '@/components/Core/Text.vue'
import Heading from '@/components/Core/Heading.vue'
import Sidebar from '@/components/Core/Sidebar.vue'
import Checkbox from '@/components/Core/Form/Checkbox.vue'
import Button from '@/components/Core/Button.vue'
import { useClipboard } from '@vueuse/core'

const importStatement = ref('import { Listbox, Option } from \'@/components/Core/Form/Listbox\'')
const { copy, copied } = useClipboard({ importStatement })

const openListboxPropSidebar = ref(false)
const openOptionPropSidebar = ref(false)
const updateKey = ref(0)

const state = ref({
  // Listbox Specific Variables/Props
  autocomplete: false,
  autocompleteWithFunction: false,
  changed: false,
  contrast: false,
  debounce: false,
  debounceNumber: 0,
  disabled: false,
  error: false,
  icon: false,
  iconColor: false,
  id: true,
  loading: false,
  minimal: false,
  modelValue: true,
  multiple: false,
  nullable: false,
  placeholder: false,

  // Listbox Playground Variables/Props
  label: true,
  initialValue: null,
  exampleValue: null,
  viewHTML: true,
  viewData: false,

  // Option Specific Variables/Props
  secondaryLabel: false,
  trailingIcon: false,
  optionKey: true,
  optionValue: true
})

const initialState = ref(JSON.parse(JSON.stringify(state.value)))

const exampleOptions = ref([
  { label: 'John Meyer', value: 'John Meyer', email: 'john@example.com'},
  { label: 'Annie Sims', value: 'Annie Sims', email: 'annie@example.com'},
  {
    label: 'Clyde Who Knows',
    value: 'Clyde Who Knows',
    email: 'clyde@example.com',
    trailingIcon: {
      icon: 'heroicons:check-badge',
      color: 'text-green-700 dark:text-green-500',
      tippy: 'Approved Contact'
    }
  },
  { label: 'Kenny Maple', value: 'Kenny Maple', email: 'kenny@example.com'},
  { label: 'Sarah Mac', value: 'Sarah Mac', email: 'sarah@example.com'},
])

const initialStateExampleOptions = ref(JSON.parse(JSON.stringify(exampleOptions.value)))

let exampleOptionsAPIResults = ref([
  { label: 'John Smith', value: 'John Smith'},
  { label: 'John Tucker', value: 'John Tucker'},
  { label: 'John Travolta', value: 'John Travolta'},
  { label: 'John Candy', value: 'John Candy'}
])

const mimicOnChange = computed(() => {
  return state.value.changed && JSON.stringify(state.value.exampleValue) !== JSON.stringify(state.value.initialValue)
})

const mimicOnError = computed(() => {
  return state.value.error && JSON.stringify(state.value.exampleValue) === JSON.stringify(state.value.initialValue)
})

const changeCodeView = (value) => {
  if (value === 'view') {
    state.value.viewHTML = true
    state.value.viewData = false
  }
  if (value === 'data') {
    state.value.viewData = true
    state.value.viewHTML = false
  }

  let optionStructure =     [
    {
      label: 'Ryan Reynolds',
      value: 'Ryan Reynolds',
      email: 'ryanreynolds@deadpool.com',
      trailingIcon: {
        icon: 'heroicons:',
        color: 'text-green-500',
        tippy: 'Greatest Actor Ever?'
      }
    }
  ]
  document.getElementById('arrayOfOptionsAsHTML').innerHTML = JSON.stringify(optionStructure, null, 2)
}

async function mimicAPICall(options) {
  return await new Promise(resolve => setTimeout(resolve, 1500))
    .then(() =>  [...options, ...exampleOptionsAPIResults.value])
}

const placeholder = computed(() => {
  // If Placeholder is checked
  if (state.value.placeholder) {
    return 'Yay! A new placeholder'
  }

  // Use Listbox default placeholder
  return null
})

const resetPlayground = () => {
  state.value = JSON.parse(JSON.stringify(initialState.value))
  exampleOptions.value = JSON.parse(JSON.stringify(initialStateExampleOptions.value))

  /**
   * We are needing to do this to force the Listbox to refresh whenever we hit reset.
   * Mainly only because of autocomplete. If we test it out with 'jo' and don't click on a user
   * the options will still have all the results even after hitting reset if this 'updateKey'
   * was not here.
  */
  updateKey.value += 1
}

/***********************************************************
 *   WATCHERS TO HANDLE FUNCTIONALITY TO MIMIC REAL WORLD
************************************************************/
// When user clicks autocomplete checkbox
watch(() => state.value.autocomplete, (autocomplete) => {
  // Reset autocompleteWithFunction as it can't be used with autocomplete
  if (autocomplete && state.value.autocompleteWithFunction) {
    state.value.autocompleteWithFunction = false
    state.value.debounce = false
    state.value.debounceNumber = 0
  }

  if (autocomplete && state.value.multiple) state.value.multiple = false
})

// When user clicks autocompleteWithFunction checkbox
watch(() => state.value.autocompleteWithFunction, (autocompleteWithFunction) => {
  // Reset autocomplete as it can't be used with autocompleteWithFunction
  if (autocompleteWithFunction && state.value.autocomplete) {
    state.value.autocomplete = false
  }

  // Set variables that can go with autocomplete
  if (autocompleteWithFunction) {
    state.value.debounce = true
    state.value.debounceNumber = 500
  }

  // If multiple is already selected, remove it.
  if (autocompleteWithFunction && state.value.multiple) state.value.multiple = false
})


// When user clicks Multiple checkbox
watch(() => state.value.multiple, (multiple) => {
  if (multiple && state.value.autocomplete) {
    state.value.autocomplete = false
  }

  if (multiple && state.value.autocompleteWithFunction) {
    state.value.autocompleteWithFunction = false
    state.value.debounce = false
    state.value.debounceNumber = 0
  }

  if (multiple && state.value.loading) {
    state.value.loading = false
  }

  // In order to change the value to array or primitive, need the watcher
  if (multiple) state.value.exampleValue = [], state.value.initialValue = []
  else state.value.exampleValue = null, state.value.initialValue = null
})

// Update Listbox Options when secondaryLabel checkbox is clicked
watch(() => state.value.secondaryLabel, () => {
  updateKey.value += 1
})

// Update Listbox Options when trailingIcon checkbox is clicked
watch(() => state.value.trailingIcon, () => {
  updateKey.value += 1
})
</script>