import { createApp } from 'vue'
import App from './App.vue'
import router from './js/router.js'
import store from './js/store/store.js'
import './assets/css/tailwind.css'
import PrimeVue from 'primevue/config'
import { definePreset } from '@primeuix/themes'
import Aura from '@primeuix/themes/aura'

const app = createApp(App)

app.config.globalProperties.$salesforce = import.meta.env.VITE_HELPER_SALESFORCE_HOST
app.config.compilerOptions.whitespace = 'preserve'
// In order to use env salesforce url inside script tag since globalProperties $salesforce
// is only allowed in template.
app.provide('baseSalesforceURL', import.meta.env.VITE_HELPER_SALESFORCE_HOST)

app.use(store)
app.use(router)

import BaseLoadingIcon from '@/components/BaseLoadingIcon.vue'
app.component('BaseLoadingIcon', BaseLoadingIcon)

// @sentry/vue
import * as Sentry from '@sentry/vue'
if (import.meta.env.VITE_HELPER_SENTRY_ENABLED === 'true') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_HELPER_SENTRY_DSN,
    environment: import.meta.env.VITE_HELPER_SENTRY_ENVIRONMENT,
    logErrors: true
  })

  Sentry.setTag('component', 'frontend')
}

// vue-tippy
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
app.use(VueTippy, {
  component: 'tippy',
  defaultProps: {
    allowHTML: true,
    arrow: true
  },
})

// vue3-apexchart
import VueApexCharts from 'vue3-apexcharts'
app.use(VueApexCharts)

// vue-gtag-next
import VueGtag from 'vue-gtag-next'
app.use(VueGtag, {
  property: {
    id: import.meta.env.VITE_GOOGLE_ANALYTICS || 'G-HWEG080L6C' // Default to SE Helper - DEV
  }
})

/**
 * Updating the Aura Preset to give some of our usuals.
 * Others will be styled on individual components.
*/
const MyPreset = definePreset(Aura, {
  semantic: {
    /**
     * Iris color taken from tailwind.config.js file.
    */
    primary: {
      100: '#e0e1ff',
      200: '#c6befe',
      300: '#ac98fd',
      400: '#9278fb',
      500: '#7855fa',
      600: '#6d40e6',
      700: '#612bd2',
      800: '#5615be',
      900: '#4b00aa'
    },
    colorScheme: {
      light: {
        primary: {
          color: '{primary.700}',
          hoverColor: '{primary.600}'
        },
        // Defaults most text to our text-charcoal-800
        text: {
          color: '#444444'
        },
        formField: {
          // Defaults most text to our text-charcoal-800
          color: '#444444',
          // Defaults most text to our text-charcoal-700
          placeholderColor: '#5d5d5d',
          // Defaults background to bg-white
          background: '#FFFFFF'
        }
      },
      dark: {
        primary: {
          color: '{primary.600}',
          hoverColor: '{primary.700}',
        },
        // Defaults most text to our text-charcoal-200
        text: {
          color: '#d7d7d7'
        },
        formField: {
          // Defaults most text to our text-charcoal-200
          color: '#d7d7d7',
          // Defaults most text to our text-charcoal-400
          placeholderColor: '#a6a6a6',
          // Defaults background to bg-charcoal-900
          background: '#2c2c2c',
          // Default form field FloatLabel color
          floatLabel: {
            focus: {
              color: '#9278fb'
            }
          }
        }
      }
    }
  }
})

// PrimeVue
app.use(PrimeVue, {
  theme: {
    preset: MyPreset,
    options: {
      darkModeSelector: '.dark',
      prefix: 'primevue',
      cssLayer: {
        name: 'primevue',
        order: 'theme, base, primevue'
      }
    }
  }
})

app.mount('#app')
